.App {
    text-align: center;
}

.step-arrow-nav .nav .nav-link.active::before {
    border-left-color: #00b900;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.nav-link {
    height: 100%;
}

.step-arrow-nav .nav {
    background-color: white;
}


.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.link-1.active {
    border-radius: 0.25em !important;
    /* border-top-left-radius: 0.25em !important;
    border-bottom-left-radius: 0.25em !important; */
    background-color: #00b900 !important;
    color: white !important;
}

.link-2.active {
    border-top-right-radius: 0.25em !important;
    border-bottom-right-radius: 0.25em !important;
    background-color: #00b900 !important;
    color: white !important;

}

.link-1.done {
    border-top-left-radius: 0.25em !important;
    border-bottom-left-radius: 0.25em !important;
    background-color: #00b900 !important;
    color: white !important;
}

.link-2.done {
    /* border-top-right-radius: 0.25em !important;
    border-bottom-right-radius: 0.25em !important; */
    background-color: #00b900 !important;
    color: white !important;
}

.link-3.done {
    /* border-top-right-radius: 0.25em !important;
    border-bottom-right-radius: 0.25em !important; */
    background-color: #00b900 !important;
    color: white !important;
}

.link-3.active {
    border-top-right-radius: 0.25em !important;
    border-bottom-right-radius: 0.25em !important;
    background-color: #00b900 !important;
    color: white !important;
}

.link-4.done {
    /* border-top-right-radius: 0.25em !important;
    border-bottom-right-radius: 0.25em !important; */
    background-color: #00b900 !important;
    color: white !important;
}

.link-4.active {
    border-top-right-radius: 0.25em !important;
    border-bottom-right-radius: 0.25em !important;
    background-color: #00b900 !important;
    color: white !important;
}

.custom-nav {
    border-radius: 0.25em;
    /* box-shadow: 0px 0px 11px -2.9px black; */
}

.step-arrow-nav .nav .nav-link.active-1 {
    border-radius: 0.25em;
    /* border-top-left-radius: 0.25em;
    border-bottom-left-radius: 0.25em; */
    background-color: #00b900;
    color: white;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.card-shadow {
    /* box-shadow: 0px 3px 14px -1.9px black; */
}

.step-arrow-nav .nav .nav-link.active-2 {
    border-top-right-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
    background-color: #00b900;
    color: white;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.step-arrow-nav .nav .nav-link.active-3 {
    border-top-right-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
    background-color: #00b900;
    color: white;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.iconbar {
    height: 100px;
    width: 100%;
    border-radius: 1em 1em 0 0;
    position: fixed;
    bottom: 0%;
    background-color: #00b900;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0px 3px 14px -1.9px black;
}

.icons {
    font-family: Nunito;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.4em;
    border-radius: 1em;
}

.icons i {
    margin-right: 0.2em;
    font-size: 2.2em;
}

.icons span {
    display: none;
    font-size: 1.2em;
    vertical-align: top;
}

/* @media only screen and (max-device-width:480px) { */
.iconbar {
    height: 80px;
    width: 100%;
}

.icons i {
    font-size: 1.6em;
}

.icons span {
    font-size: 1em;
}

/* } */

a {
    color: white;
}

a.active {
    font-size: 20px;
}

a:active, a:hover {
    color: white;
}

.bg-line {
    background-color: #00b900;
    color: white;
}

button>i {
    margin-right: 10px;
}

.page-content {

    padding-top: 10px !important;
}

.modal {
    align-content: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

body {
    background-color: whitesmoke;
    font-family: 'Mitr', sans-serif;
    /* background-image: url('../assets/images/cover.jpeg') !important; */
}

.pull-right {
    float: right !important;
}

.btn-footer {
    width: 100%;
    position: fixed;
    bottom: 0%;
}

.img-center {
    display: block;
    margin: 0 auto;
}

.nav-order {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 999;
}

.navbar .bg-line {

    background-color: #09B801 !important;
    color: white;
    position: fixed;
    width: 100%;
    z-index: 99;
}

.footer-section {
    position: static !important;
    bottom: 0%;
    width: 100%;
}

.cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 160px;
    height: 1.2em;
    white-space: nowrap;
}

.bg-line {
    background-color: #09B801 !important;
    color: white;
}

.btn-line {
    background-color: #09B801;
    color: white;
    border-radius: 0px !important;
}

.btn-line:active, .btn-line:hover {
    color: #41464b !important;
}

.btn-footer button i {
    font-size: 25px;
}



.fa-td {
    display: revert;
}

i {
    padding: 0px !important;
}

.color-A {
    background-color: #FF6700;
    color: black;
}

.color-B {
    background-color: #35CCFE;
    /* background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%); */
    color: black;
}

.color-C {
    background-color: #99CB32;
    color: black;
}


.color-D {
    background-color: #FF3333;
    color: white;
}


.color-E {
    background-color: #FFCC00;
    color: black;
}



.color-F {
    background: #929292;
    color: black;
}


.color-G {
    background-color: #BF43BE;
    color: black;
}

.color-H {
    background-color: #FF9933;
    color: black;
}

.color-I {
    background-color: #C85F3C;
    color: black;
}

.form-amount {
    text-align: right;
}

.fa-td {
    font-size: 30px;
}

table.layout {

    background-color: white;
}

table.layout td {
    text-align: center;
    width: 50px !important;
    height: 50px !important;
    cursor: pointer;
}

table.layout td.empty {
    width: 50px;
    height: 50px;
    border: unset;
}

table.layout td.border {
    /* border: 1px solid black !important; */
    width: 50px !important;
    height: 50px !important;
}

table.layout td.active {
    background-color: red;
    color: white;
}

table.layout td {
    z-index: 999;
}

.map-box {
    width: 800px;
    height: 1000px;
}

.boxes {
    width: 50%;
    margin: 0 auto;
}

.box {
    /* display: inline-block; */
    width: 24%;
    height: 15em;
    text-align: center;
    line-height: 15em;
    color: #fff;
}

.one {
    background: #333;
}

.two {
    background: #fff;
    color: #333;
}

.three {
    background: #aaa;
}

.four {
    background: #dc002e;
}

/* 768px */
@media screen and (max-width:48em) {
    .boxes {
        display: table;
        width: 100%;
    }

    .box {
        display: block;
        width: 100%;
    }

    .three {
        display: table-caption;
    }

    .four {
        display: table-header-group;
    }

    .one {
        display: table-footer-group;
    }
}

/* Style the list */
ul.breadcrumb {
    padding: 10px 16px;
    list-style: none;
    background-color: #eee;
}

/* Display list items side by side */
ul.breadcrumb li {
    display: inline;
    font-size: 18px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: "/\00a0";
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
    color: #0275d8;
    text-decoration: none;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
    color: #01447e;
    text-decoration: underline;
}

.jumbotron {
    padding: 30px;
    background-color: #eeeeee;
}

.flexbox-container {
    background: teal;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    margin: 0;
    min-height: 24em;

}

.flexbox-item {
    max-width: 50%;
    background: gray;

}

.fixed {
    flex: none;
    max-width: 50%;
}

.demo {
    width: 100%;
    padding: 1em;
    background: olive;
}


.navbar-rent {

    right: 0;
    position: fixed;
    top: 2.5%;
    z-index: 9;
}

.page-content {
    margin-top: 0px !important;
}